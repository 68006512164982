@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Text:ital,wght@0,300..700;1,300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.jodit-container:not(.jodit_inline) .jodit-wysiwyg {
  margin: 0;
  height: 300px !important;
  outline: 0;
  overflow-x: auto;
  padding: 8px;
  position: relative;
}
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border-color: var(--ck-color-base-border);
  min-height: 180px !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.custom-width {
  width: 100% !important;
}

.App-link {
  color: #61dafb;
}

* {
  scroll-behavior: smooth;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Libre Caslon Text";
  src: url("../src/assets/fonts/LibreCaslonText-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";

  src: url("../src/assets/fonts/Inter-Bold.ttf");
  src: url("../src/assets/fonts/Inter-SemiBold.ttf");
  src: url("../src/assets/fonts/Inter-Medium.ttf");
  src: url("../src/assets/fonts/Inter-Regular.ttf");
}
.poppins {
  font-family: "Poppins", sans-serif;
  font-weight: auto;
  font-style: normal;
}

.jodit-workplace + .jodit-status-bar:not(:empty) {
  border-top: 1px solid #dadada;
  display: none;
}

.inter_ff {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: weight;
  font-style: normal;
}
.source_ff {
  font-family: "SourceSansPro";
  font-optical-sizing: auto;
  font-weight: weight;
  font-style: italic;
}
.libre_ff {
  font-family: "Libre Caslon Text", sans-serif;
  font-optical-sizing: auto;
  font-weight: weight;
  font-style: normal;
}

.dropdown-container {
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.marquee {
  display: flex;
  animation: marquee 30s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.dropdown-item {
  border-bottom: 1px solid #e5e7eb;
  padding: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.2s;
}
.jodit-container:not(.jodit_inline) {
  background-color: white;
  border: 0px solid #ffffff;
  border-radius: 0px;
}

.dropdown-item:hover {
  background-color: #f1f5f9;
}

.dropdown-item:last-child {
  border-bottom: none;
}

.dropdown-content {
  padding: 1rem;
  background-color: transparent !important;
}

/* Reviews.css */
.custom-carousel .carousel .control-dots {
  position: static !important;
  margin-top: 1rem !important;
  text-align: center !important;
}
.carousel.carousel-slider {
  position: relative;
  margin: 0;
  display: flex !important;
  flex-direction: column-reverse !important;
  overflow: hidden;
}
.custom-carousel .carousel .control-dots .dot {
  background-color: #d3d3d3 !important;
  width: 10px !important;
  height: 10px !important;
  margin: 0 5px !important;
  border-radius: 50% !important;
}

.custom-carousel .carousel .control-dots .dot.selected {
  background-color: #212529 !important;
}

.spinner {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000000;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.jodit-container:not(.jodit_inline) .jodit-workplace {
  height: 550px !important;
}

.custom_bgColor {
  background-color: #008cd2;
}

.spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.jodit-react-container {
  width: 100% !important;
}

.Toastify__progress-bar--success {
  background: #008cd2 !important;
}

.Toastify__toast-icon svg {
  fill: #008cd2 !important;
}
/* 
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Text:ital,wght@0,300..700;1,300..700&display=swap");
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



@font-face {

  src: url(../src/assets/fonts/LibreCaslonText-Bold.ttf);
  src: url(../src/assets/fonts/LibreCaslonText-Bold.ttf);
  src: url(../src/assets/fonts/LibreCaslonText-Bold.ttf);
  src:url(../src/assets/fonts/Inter-Black.ttf);
  src:url(../src/assets/fonts/Inter-Bold.ttf);
  src:url(../src/assets/fonts/Inter-ExtraBold.ttf);
  src:url(../src/assets/fonts/Inter-Light.ttf);
  src:url(../src/assets/fonts/Inter-SemiBold.ttf);
  src:url(../src/assets/fonts/Inter-Thin.ttf);
}



 */
